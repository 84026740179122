import { CAModal } from '@careacademy/ca-design-system'
import { defineComponent, type PropType } from 'vue'

import { on } from '@/javascript/vuejs/helpers/emitEventHandler'
import GlobalStore from '@/javascript/vuejs/stores/GlobalStore'

const emits = {
	cancel: (): boolean => true,
	confirm: (): boolean => true,
	reconfirm: (): boolean => true,
}

export default defineComponent({
	name: 'ConfirmationDialog',

	props: {
		...on(emits),
		additionalClasses: { default: '', type: String },
		byPassEmittedEvent: { default: false, type: Boolean },
		dataTest: { default: '', type: String },
		enableFooter: { default: true, type: Boolean },
		modalSize: { default: 'sm', type: String },
		modalStyle: { default: 'success', type: String as PropType<'danger' | 'success'> },
		name: { required: true, type: String },
		reconfirm: { default: false, type: Boolean },
		showCancelButton: { default: true, type: Boolean },
		showSubmitButton: { default: true, type: Boolean },
		submitText: { default: 'Confirm', type: String },
	},

	emits,

	methods: {
		onCloseModal(): void {
			this.$emit('cancel')
			GlobalStore.resetCAModal()
		},

		onHandleConfirm(): void {
			if (this.reconfirm) {
				this.$emit('reconfirm')
			} else {
				this.$emit('confirm')
			}
		},
	},

	render() {
		return <CAModal
			additionalClasses={`ca-modal__confirmation-dialog ${this.additionalClasses}`}
			dataTest={this.dataTest}
			enableFooter={this.enableFooter}
			isOpen={GlobalStore.emittedEvent === this.name || this.byPassEmittedEvent}
			modalSize={this.modalSize}
			modalStyle={this.modalStyle}
			showCancelButton={this.showCancelButton}
			showSubmitButton={this.showSubmitButton}
			submitText={this.submitText}
			title="Are you sure?"
			onCancel={this.onCloseModal}
			onSubmit={this.onHandleConfirm}
		>
			{this.$slots.default}
		</CAModal>
	},
})
